<template>
  <div
    id="identity"
    class="identity d-flex login-layout min-vw-100 min-vh-100"
    :class="{ 'customized-affiliate': !!affiliateCustomizations }"
    :style="appStyleProps">
    <slot />
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'
import { useRootStore } from '~/store/root'
import env from '~/libs/env'

export default {
  async setup() {
    const rootStore = useRootStore()
    const affiliateCustomizationStore = useAffiliateCustomizationStore()
    rootStore.setPageTitle(affiliateCustomizationStore.headTitlePrefix + ' - Sign In')
    rootStore.setLendioFavicons()
    useHead({
      title: rootStore.pageTitle,
      link: rootStore.favicons,
    })
  },
  computed: {
    ...mapState(useAffiliateCustomizationStore, [
      'appStyleProps',
      'affiliateCustomizations',
    ]),
  },
}
</script>

<style lang="scss" scoped>
@import "assets/styles/affiliate-customizations/_affiliate-customizations-identity.scss";

#identity {
  &.customized-affiliate :deep(.identity-component) {
    @include affiliateCustomizedIdentity();
  }
}

.login-layout {
  background-color: #F4F1F0;
}

.identity :deep(button) {
  height: 52px;
}

.identity :deep(.text-tooltip-icon) {
  position: absolute;
  top: 0.8rem;
  right: 1rem;
  color: $gray-500;
  cursor: pointer;
}
</style>
